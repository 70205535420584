<template>
	<div class="container">
		<div class="title-wrap">
			<h2 class="title">{{ boardName }}</h2> <el-button v-if="article_load" type="primary" @click="$router.push('/board/'+$route.params.id)" style="display: inline-block; float: right;">목록으로</el-button>
		</div>

    <el-card class="box-card" v-if="article_load"> 
      <div slot="header" class="clearfix">
        <span><b>{{ title }}</b></span>
        <span style="float: right;"><i class="el-icon-user-solid"></i>&nbsp;{{ writer }}&nbsp;&nbsp;&nbsp;&nbsp;<i class="el-icon-alarm-clock"></i>&nbsp;{{ time }}</span>
      </div>
      <div class="fr-view" v-html="content"></div>

    </el-card>
      <div style="float: right;" v-if="article_load && canEdit">
        <el-button type="warning" @click="$router.push('/board/'+$route.params.id+'/'+$route.params.idx+'/edit')">수정</el-button>
        <el-button type="danger" @click="articleDelete">삭제</el-button>
      </div>    
 
	</div>
</template>

<script>
import axios from "axios";
import {apiUrl} from '../constants/config';
import { Loading } from 'element-ui';

var loadingInstance;

export default {
	data() {
		return {
			article_load: false,
      boardName: "",
      canEdit: false,
      title: "",
      time: "",
      writer: "",
      content: ""
		}
	},
	methods: {
    articleDelete(){
      var vm = this;
      vm.$swal.fire({
					title: '해당 게시글을 삭제하시겠습니까?',
					html: "삭제한 게시글은 복구하실 수 없습니다.",
					icon: 'warning',
					showDenyButton: true,
					showCancelButton: false,
					confirmButtonText: '삭제하기',
					denyButtonText: `닫기`,
					}).then((result) => {
					if (result.isConfirmed) {
            
            axios.get(apiUrl+'/article/'+this.$route.params.idx+'/delete', { withCredentials: true })
                .then(function(response) {
                  if(response.data.success){
                     vm.$swal.fire(
                      '게시글이 삭제되었습니다.',
                      "게시판에서 게시글을 조회하실 수 없습니다.",
                      'success'
                    ).then(() => {
                      vm.$router.push("/board/"+vm.$route.params.id);

                    } )
                  }
                })
                .catch(function(error) {
                  console.log(error);
                });


					} 
				})
    }
	},
	mounted(){
	},
  beforeCreate(){
    loadingInstance = Loading.service({ fullscreen: true });

    var vm = this;
    axios.get(apiUrl+'/board/'+this.$route.params.id+'/', { withCredentials: true })
    .then(function(response) {
      if(response.data.success){
        vm.boardName = response.data.boardName;
      }
      else{
      vm.$router.push("/");
      }
    })
    .catch(function(error) {
      console.log(error);
    });


    axios.get(apiUrl+'/article/'+this.$route.params.idx, { withCredentials: true })
    .then(function(response) {
      if(response.data.success){
        vm.canEdit = response.data.canEdit;
        vm.title = response.data.title;
        vm.time = response.data.time;
        vm.writer = response.data.writer;
        vm.content = response.data.content;
        vm.article_load = true;
        loadingInstance.close();
      }
      else{
        loadingInstance.close();

        vm.$swal.fire(
						'권한이 없습니다.',
						"해당 게시글을 조회할 권한이 없습니다.",
						'error'
					).then(() => {
						vm.$router.push("/board/"+vm.$route.params.id);

					} 
          )
      }
    })
    .catch(function(error) {
      console.log(error);
    });
  }
}
</script>

<style scoped>
.box-card {
  margin-bottom: 15px;
}


</style>
